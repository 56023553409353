import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Footer from '@gtg/components/footer'
import Nav from '@gtg/components/nav'
import Seo from '@gtg/components/seo'
import { fontSize } from '@gtg/styles/theme'
import Link from 'gatsby-link'
import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'
import GlobalWrapper from '../components/globalWrapper'
import StretchzeltSrc from '../img/stretchzelt.jpg'
import Stretchzelt2Src from '../img/stretchzelt2.jpg'
import Stretchzelt3Src from '../img/stretchzelt3.jpg'

const GridLayout = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 5rem;
  margin: 5rem 0;
  ${media.lg`
    grid-gap: 4rem;
    margin: 3rem 0;
  `}
  ${media.md`
    grid-gap: 2rem;
  `}
  ${media.sm`
    margin: 2rem 0;
    grid-gap: 1rem;
  `}
`

const Paragraph1 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 4rem;
  ${media.sm`
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-gap: 1rem;
  `}
`

const Paragraph3 = styled.div`
  display: grid;
  grid-template-columns: 3fr 5fr;
  grid-template-rows: auto;
  grid-gap: 4rem;
  ${media.sm`
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-gap: 1rem;
  `}
`

const Stretchzelt = styled.div`
  min-height: 300px;
  background: #f1f1f1;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: 50% 15%;
  ${media.sm`
    width: 100%;
    min-height: 200px;
  `}
`

const Stretchzelt2 = styled.div`
  min-height: 300px;
  background: #f1f1f1;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: 50% 15%;
  width: 100%;
`

const Stretchzelt3 = styled.div`
  min-height: 300px;
  background: #f1f1f1;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: 50% 15%;
  width: 100%;
  z-index: 3;
  margin: 4rem 0;
  ${media.md`
    margin: 0;
  `}
  ${media.sm`
    width: 100%;
    margin: 1rem 0;
    min-height: 200px;
  `}
`

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 3rem;
  grid-column-gap: 6rem;
  ${media.lg`
    grid-row-gap: 1rem;
  `}
  ${media.md`
    display: block;
  `}
`

const LeftContainer = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  display: flex;
  align-items: center;
`

const RightContainer = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / 3;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  ${media.sm`
    display: none;
  `}
`

const RightBackgroundContainer = styled.div`
  position: absolute;
  grid-row: 1 / 2;
  background: linear-gradient(
    97deg,
    rgba(36, 58, 74, 0) 0%,
    rgba(36, 58, 74, 0) 7%,
    rgba(36, 58, 74, 1) 7.1%
  );
  width: 100vw;
  height: 100%;
  margin-left: -4vw;
  ${media.md`
    background: #243a4a;
    margin: 0;
    width: 100vw;
    margin-left: -3rem;
  `}
  ${media.sm`
    margin-left: -1rem;
  `}
  ${media.xs`
    margin-left: -0.5rem;
  `}
`

const TextContainer = styled.div`
  display: flex;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  color: white;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  ${media.md`
    color: #243a4a;
    margin-top: 2rem;
  `}
  ${media.sm`
    margin-top: 1rem;
  `}
`

const CTA = styled(Box)`
  background: #ecf2f8;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 5fr 3fr;
  grid-gap: 4rem;
  ${media.sm`
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-gap: 1rem;
  `}
`

const Text = styled.div`
  max-width: 500px;
`

const Heading = styled.h2`
  font-size: ${fontSize.f7};
  text-transform: none;
  margin-bottom: 2rem;
  ${media.sm`
    font-size: ${fontSize.f6};
  `}
`
const CTAButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`

const Button = styled.button`
  padding: 1.25rem 4rem;
  background: #006e9a;
  font-weight: 600;
  letter-spacing: 0.3px;
  ${media.xl`
    padding: 1rem 3rem;
  `}
  ${media.sm`
    width: 100%;
  `}
  :hover {
    background: #009cda;
  }
`

const Wedding = () => {
  return (
    <GlobalWrapper>
      <Seo title="Hochzeitszelte" />
      <Nav caps wMax="xl" withBorder />
      <section>
        <Container wMax="lg">
          <Box pxMax="md" pyMax="lg">
            <h1>Die beste Überdachung für Ihre Hochzeit</h1>
            <GridLayout>
              <Paragraph1>
                <div>
                  <h2>Mehr Platz für Ihre Gäste</h2>
                  <p>
                    Sie wünschen sich eine individuelle und einzigartige
                    Hochzeit? Dann sind unsere Stretchzelte die perfekte
                    Überdachung!
                  </p>
                  <p>
                    Ob Tanzfläche, Catering, Lounge, Sitzgelegenheiten im Freien
                    oder Tische inklusive Bestuhlung. In Aufbau und Form
                    unendlich flexibel, sind Ihre Gäste unter einem Stretchzelt
                    im Freien geschützt vor Regen, Wind und direkter
                    Sonneneinstrahlung.
                  </p>
                  <p>
                    So sind Sie unabhängig vom Wetter und flexibler in der
                    Anzahl der Gäste.
                  </p>
                </div>
                <Stretchzelt src={StretchzeltSrc}></Stretchzelt>
              </Paragraph1>
              <GridContainer>
                <LeftContainer>
                  <Stretchzelt3 src={Stretchzelt3Src}></Stretchzelt3>
                </LeftContainer>
                <RightContainer>
                  <RightBackgroundContainer />
                </RightContainer>
                <TextContainer>
                  <p>
                    In Kombination mit stimmungsvoller Beleuchtung ergibt sich
                    eine kunstvolle Kulisse unter der Sie mit Ihren Gästen
                    gemütlich und geborgen bis spät in die Nacht zusammensitzen
                    können.
                  </p>
                  <p>
                    So wird Ihr Hochzeitstag und die darauffolgende Nacht mit
                    den Freunden und der Familie zu einem einzigartigen Event
                    und für alle eine traumhafte Erinnerung bleiben.
                  </p>
                  <p>
                    Stretchzelte passen sich natürlich an unterschiedliche
                    Locations an, besitzen einen sehr dynamischen, modernen Look
                    und schaffen eine Atmosphäre schlichter Eleganz und
                    Natürlichkeit.
                  </p>
                </TextContainer>
              </GridContainer>
              <Paragraph3>
                <div>
                  <p>
                    Stretchzelte wurden ursprünglich als Beduinen- und
                    Wüstenzelte eingesetzt und kamen aufgrund ihrer
                    Funktionalität und Flexibilität an den unterschiedlichsten
                    exotischsten Orten zum Einsatz.
                  </p>
                  <p>
                    Bald darauf wurden sie aufgrund ihrer einzigartigen Optik
                    auch auf extravaganten Festivals (z.B. Coachella, Fusion etc
                    ), feinen Dinnerparties oder hochklassigen Veranstaltungen
                    aufgegriffen.
                  </p>
                  <p>
                    Inzwischen kommen die dehnbaren Zelte aufgrund der Vielzahl
                    an Qualitäten auch immer häufiger als Pavillon- und
                    Festzeltersatz für Hochzeiten zum Einsatz.
                  </p>
                </div>
                <Stretchzelt2 src={Stretchzelt2Src}></Stretchzelt2>
              </Paragraph3>
            </GridLayout>
            <CTA pMax="lg">
              <Text>
                <Heading>
                  Wie viel kostet die Überdachung meiner Hochzeit?
                </Heading>
                <p>
                  Nutzen Sie jetzt den praktischen Mietpreis-Rechner um Ihre
                  Veranstaltung zu planen.
                </p>
              </Text>
              <CTAButtonWrapper>
                <Link to="/#calculator">
                  <Button>Jetzt Mietpreis berechnen</Button>
                </Link>
              </CTAButtonWrapper>
            </CTA>
          </Box>
        </Container>
      </section>
      <Footer background="#0c1821" />
    </GlobalWrapper>
  )
}

export default Wedding
